export type IProps = {
	from: {
		enabled: boolean;
		props?: {
			[key: string]: unknown;
		};
	};
	children: React.ReactNode;
};

export default function Module(props: IProps) {
	return props.from.enabled ? <>{props.children}</> : null;
}
