"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAvailableProducts = void 0;
exports.EAvailableProducts = {
    pierval_sante: "pierval_sante",
    coeur_de_regions: "coeur_de_regions",
    activimmo: "activimmo",
    epargne_pierre: "epargne_pierre",
    vendome_regions: "vendome_regions",
    coeur_d_europe: "coeur_d_europe",
    iroko_zen: "iroko_zen",
    neo: "neo",
    kyaneos_pierre: "kyaneos_pierre",
    sofidy_europe_invest: "sofidy_europe_invest",
    novapierre_allemagne_2: "novapierre_allemagne_2",
    la_fonciere_des_praticiens: "la_fonciere_des_praticiens",
    cristal_rente: "cristal_rente",
    gma_essentialis: "gma_essentialis",
    eurovalys: "eurovalys",
    epargne_pierre_europe: "epargne_pierre_europe",
    immorente: "immorente",
    ncap_continent: "ncap_continent",
    remake_live: "remake_live",
    altixia_cadence_xii: "altixia_cadence_xii",
    alitixia_commerces: "alitixia_commerces",
    alta_convictions: "alta_convictions",
    transitions_europe: "transitions_europe",
    ncap_education_sante: "ncap_education_sante",
    optimale: "optimale",
    atream_hotels: "atream_hotels",
    interpierre_france: "interpierre_france",
    log_in: "log_in",
    comete: "comete",
    upeka: "upeka",
    prima_paref: "prima_paref",
    corum_origin: "corum_origin",
    corum_eurion: "corum_eurion",
    corum_xl: "corum_xl",
    buroboutic: "buroboutic",
    cap_fonciere_et_territoires: "cap_fonciere_et_territoires",
    pfo: "pfo",
    selectipierre_2: "selectipierre_2",
    sofidynamic: "sofidynamic",
    wemo_one: "wemo_one",
    a_placement_pierre: "a_placement_pierre",
    aref_evo: "aref_evo",
    lf_europimmo: "lf_europimmo",
    lf_grand_paris_patrimoine: "lf_grand_paris_patrimoine",
    pf_grand_paris: "pf_grand_paris",
    pfo_2: "pfo_2",
    primopierre: "primopierre",
    aestiam_pierre_rendement: "aestiam_pierre_rendement",
    coeur_de_ville: "coeur_de_ville",
    ficommerce: "ficommerce",
    sofiboutique: "sofiboutique",
    novapierre_1: "novapierre_1",
    patrimmo_commerce: "patrimmo_commerce",
    cristal_life: "cristal_life",
    efimmo: "efimmo",
    elialys: "elialys",
    epargne_fonciere: "epargne_fonciere",
    urban_coeur_commerces: "urban_coeur_commerces",
    lf_opportunite_immo: "lf_opportunite_immo",
    grand_paris_residentiels: "grand_paris_residentiels",
    novapierre_residentiel: "novapierre_residentiel",
    primofamily: "primofamily",
    patrimmo_impact_croissance: "patrimmo_impact_croissance",
    sofiprime: "sofiprime",
    pierre_expansion_sante: "pierre_expansion_sante",
    lf_avenir_sante: "lf_avenir_sante",
    primovie: "primovie",
    aestiam_cap_hebergimmo: "aestiam_cap_hebergimmo",
    logipierre_3: "logipierre_3",
    pf_hospitalite_europe: "pf_hospitalite_europe",
    acces_valeur_pierre: "acces_valeur_pierre",
    accimmo_pierre: "accimmo_pierre",
    credit_mutuel_pierre_1: "credit_mutuel_pierre_1",
    epsilon_360: "epsilon_360",
    lf_les_grands_palais: "lf_les_grands_palais",
    selectinvest_1: "selectinvest_1",
    darwin: "darwin",
    reason: "reason",
    patrimonia_capital_rendement: "patrimonia_capital_rendement",
    osmo_energie: "osmo_energie",
    epargne_pierre_sophia: "epargne_pierre_sophia",
    elevation_tertiom: "elevation_tertiom",
    lina_club: "lina_club",
    corum_usa: "corum_usa",
};
