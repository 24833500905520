import { EProductDocumentType } from "common/enums/ProductSheet/DocumentsTemplates/EProductDocumentType";
import { ESubStep } from "common/enums/Subscription/ESubStep";
import ProductSubscribedToFileAdminRequestResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileAdminRequestResource";
import ProductSubscribedToFileResponseResource from "common/resources/ProductSubscribed/File/ProductSubscribedToFileResponseResource";
import ProductSubscribedResponseResource from "common/resources/ProductSubscribed/ProductSubscribedResponseResource";
import ProductSubscriptionUpdateResource from "common/resources/ProductSubscribed/ProductSubscriptionUpdateResource";
import { IPagination } from "components/elements/InfiniteScroll";
import { singleton } from "tsyringe";

import BaseAdminApi from "./BaseAdminApi";

@singleton()
export default class ProductSubscribedApi extends BaseAdminApi {
	private baseurl = `${this.apiUrl}/product_subscribed`;

	public async getAllByStep(steps: ESubStep[], pagination: IPagination, search?: string | null) {
		const urlParams = new URLSearchParams();
		urlParams.append("_take", pagination.take.toString());
		urlParams.append("_skip", pagination.skip.toString());
		search && urlParams.append("_search", search);
		const url = `${this.baseurl}/steps/${JSON.stringify(steps)}?${urlParams.toString()}`;

		return this.getRequest<ProductSubscribedResponseResource[]>(url);
	}

	public async countBySteps(steps: ESubStep[], search?: string | null) {
		const urlParams = new URLSearchParams();
		search && urlParams.append("_search", search);
		const url = `${this.baseurl}/count/${JSON.stringify(steps)}?${urlParams.toString()}`;
		return this.getRequest<{ count: number }>(url);
	}

	public async getByUid(uid: string) {
		const url = `${this.baseurl}/${uid}`;
		return this.getRequest<ProductSubscribedResponseResource>(url);
	}

	public async updateStatus(uid: string, body: ProductSubscriptionUpdateResource): Promise<ProductSubscribedResponseResource> {
		const url = `${this.baseurl}/${uid}`;
		return this.putRequest(url, { ...body });
	}

	public async uploadFile(file: File) {
		const url = `${this.baseurl}/upload-file`;
		const formData = new FormData();
		formData.append("file", file);
		return this.postRequestFormdata<ProductSubscribedResponseResource>(url, formData);
	}

	public async attachFile(attachedFile: ProductSubscribedToFileAdminRequestResource) {
		const url = `${this.baseurl}/attach-file`;
		return this.postRequest<ProductSubscribedToFileResponseResource>(url, { ...attachedFile });
	}

	public async deleteFile(fileUid: string) {
		const url = `${this.baseurl}/${fileUid}`;
		return this.deleteRequest(url);
	}

	public buildFileUrlByFileId(id: string) {
		return `${this.baseurl}/file/${id}`;
	}

	public async getSignableDocumentsTypes(productSubscribedId: string) {
		const url = `${this.baseurl}/${productSubscribedId}/signable-documents-types`;
		return this.getRequest<EProductDocumentType[]>(url);
	}

	public buildSignableDocumentUrl(productSubscribedId: string, docType: EProductDocumentType) {
		return `${this.baseurl}/${productSubscribedId}/signable-documents/${docType}`;
	}

	public buildSignableDocumentsZipUrl(productSubscribedId: string) {
		return `${this.baseurl}/${productSubscribedId}/signable-documents-zip`;
	}

	public buildRtoSignableDocument(productSubscribedId: string) {
		return `${this.baseurl}/${productSubscribedId}/rto-signable-document`;
	}

	public async updateToLastProductSheet(productSubscribedId: string) {
		const url = `${this.baseurl}/update-to-last-product-sheet`;
		return this.postRequest(url, {
			productSubscribedId,
		});
	}
}
