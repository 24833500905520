import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { Divider } from "@mui/material";
import { EMatrimonialRegime } from "common/enums/ClientSheet/EMatrimonialRegime";
import { EFinancialObjective } from "common/enums/ProductSubscribed/EFinancialObjective";
import { EFundsOrigin } from "common/enums/ProductSubscribed/EFundsOrigin";
import { EFundsOriginCommunityInvestment } from "common/enums/Subscription/EFundsOriginCommunityInvestment";
import { EPaymentMethod } from "common/enums/ProductSubscribed/EPaymentMethod";
import { ECommunityInvestment } from "common/enums/Subscription/ECommunityInvestment";
import UpdatePaymentMethodRequestResource from "common/resources/ProductSubscribed/UpdatePaymentMethodRequestResource";
import { ValidationError } from "common/resources/Resource";
import SubscriptionUpdateRequestResource from "common/resources/Subscription/SubscriptionUpdateRequestResource";
import Button from "components/elements/Button";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import SubscriptionService from "services/SubscriptionService";
import { container } from "tsyringe";
import FormUtils from "utils/FormUtils";

import { ISubscriptionOutletContext } from "..";
import classes from "./classes.module.scss";
import PaymentMethodInputElement, { PrelevementInputs } from "./PaymentMethodInputElement";
import ClientService from "services/ClientService";
import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import { ESubscriptionType } from "common/enums/Subscription/ESubscriptionType";
import { ECountry } from "common/enums/Country/ECountry";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import QuestionCategory from "../QuestionCategory";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import { EMaritalStatus } from "common/enums/ClientSheet/EMaritalStatus";

const subscriptionService = container.resolve(SubscriptionService);
const clientService = container.resolve(ClientService);

export default function PaymentMethodsCorporation() {
	const { subscriptionId } = useParams();
	const {
		subscription,
		subscriptionWithKnowledge,
		goToNextStep,
		loadSubscription,
		setSubscriptionHasUnsavedChangesToTrue,
		setSubscriptionHasUnsavedChangesToFalse,
		currentSelectedPath,
		setCurrentSelectedPath,
	} = useOutletContext<ISubscriptionOutletContext>();
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [isOtherChecked, setIsOtherChecked] = useState(subscriptionWithKnowledge.fundsOrigin?.some((value) => value === EFundsOrigin.other) || false);
	const [isSavingChecked, setIsSavingChecked] = useState(subscriptionWithKnowledge.fundsOrigin?.some((value) => value === EFundsOrigin.savings) || false);
	const [isPaymentFromFrenchAccount, setIsPaymentFromFrenchAccount] = useState(subscriptionWithKnowledge.paymentFromFrenchAccount ?? true);
	const [isPaymentByTier, setIsPaymentByTier] = useState(subscriptionWithKnowledge.paymentByTier ?? false);

	const [communityInvestment, setCommunityInvestment] = useState<ECommunityInvestment | null>(subscriptionWithKnowledge.communityInvestment ?? null);
	const [fundsOriginCommunityInvestment, setFundsOriginCommunityInvestment] = useState<EFundsOriginCommunityInvestment | null>(
		subscriptionWithKnowledge.fundsOriginCommunityInvestment ?? null,
	);
	const navigate = useNavigate();

	const [useSameRib, setUseSameRib] = useState(true);
	const [useSamePrelevement, setUseSamePrelevement] = useState(true);
	const [spouse, setSpouse] = useState<ClientSheetResponseResource | null>(null);

	const showCommunityInvestment = useMemo(() => {
		const subscriber = subscriptionWithKnowledge.productsSubscribed?.[0]?.subscriber;
		if (!subscriber?.matrimonialRegime) return false;
		const isRegimeMatrimonialCommunity = [
			EMatrimonialRegime.communaute_meuble_et_acquets,
			EMatrimonialRegime.communaute_reduite_aux_acquets,
			EMatrimonialRegime.communaute_universelle,
		].includes(subscriber.matrimonialRegime);

		const isPacsIndivision = subscriber.matrimonialRegime === EMatrimonialRegime.indivision && subscriber.maritalStatus === EMaritalStatus.pacse;

		return isRegimeMatrimonialCommunity || isPacsIndivision;
	}, [subscriptionWithKnowledge]);

	const [prelevementInputs, setPrelevementInputs] = useState<PrelevementInputs>({
		titularyName: subscriptionWithKnowledge.perceptionTitularyName ?? subscriptionWithKnowledge.productsSubscribed![0]!.titularyName ?? "",
		bankDomiciliation: subscriptionWithKnowledge.perceptionBankDomiciliation ?? subscriptionWithKnowledge.productsSubscribed![0]!.bankDomiciliation ?? "",
		iban: subscriptionWithKnowledge.perceptionIban ?? subscriptionWithKnowledge.productsSubscribed![0]!.iban ?? "",
		bic: subscriptionWithKnowledge.perceptionBic ?? subscriptionWithKnowledge.productsSubscribed![0]!.bic ?? "",
	});

	const onPrelevementChange = useCallback((value: PrelevementInputs) => {
		setPrelevementInputs(value);
	}, []);

	useEffect(() => {
		const spouseId = subscriptionWithKnowledge.productsSubscribed?.[0]?.subscriber?.spouseId;
		if (!spouseId || !(showCommunityInvestment && subscriptionWithKnowledge.subscriptionType === ESubscriptionType.naturalPerson)) return;
		clientService.getClientSheetById(spouseId).then((clientSheet) => {
			setSpouse(clientSheet);
		});
	}, [showCommunityInvestment, subscriptionWithKnowledge]);

	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			if (!subscription) return console.warn("No subscription id provided");
			e.preventDefault();
			setErrors([]);

			let hasErrors = false;
			const subscriptionResource = SubscriptionUpdateRequestResource.hydrate<SubscriptionUpdateRequestResource>({
				isRegimeMatrimonialCommunity: showCommunityInvestment,
				subscriptionType: subscription.subscriptionType,
				subscriptionId: subscription.id,
				financialObjective: FormUtils.getEnumValues<EFinancialObjective>(formData, "financialObjective", EFinancialObjective),
				fundsOrigin: FormUtils.getEnumValues<EFundsOrigin>(formData, "fundsOrigin", EFundsOrigin),
				fundsOriginOther: ((formData["fundsOriginOther"] as string) || "").trim() || null,
				fundsOriginSavings: ((formData["fundsOriginSavings"] as string) || "").trim() || null,
				signaturePlace: ((formData["signaturePlace"] as string) || "").trim() || null,
				communityInvestment: showCommunityInvestment ? (formData["communityInvestment"] as ECommunityInvestment) : null,
				fundsOriginCommunityInvestment: FormUtils.getEnumValue<EFundsOriginCommunityInvestment>(
					formData,
					"fundsOriginCommunityInvestment",
					EFundsOriginCommunityInvestment,
				),
				fundsOriginCommunityInvestmentOther: formData["fundsOriginCommunityInvestmentOther"] as string,
				communitySpouseDetails:
					showCommunityInvestment && subscription.subscriptionType === ESubscriptionType.naturalPerson
						? {
								firstName: formData["communitySpouseDetails.firstName"] as string,
								lastName: formData["communitySpouseDetails.lastName"] as string,
								email: formData["communitySpouseDetails.email"] as string,
								mobilePhone: formData["communitySpouseDetails.mobilePhone"] as string,
								birthDate: new Date(formData["communitySpouseDetails.birthDate"] as string),
								birthPlace: formData["communitySpouseDetails.birthPlace"] as string,
							}
						: null,
				fundsOriginCorporation: [],
				paymentByTier: FormUtils.getBooleanValue(formData, "paymentByTier"),
				paymentByTierMotive: formData["paymentByTierMotive"] as string,
				paymentFromCountry: formData["paymentFromCountry"] as ECountry,
				paymentFromFrenchAccount: FormUtils.getBooleanValue(formData, "paymentFromFrenchAccount"),
				apportCompteCourant: null,
				perceptionBankDomiciliation: ((formData["perceptionBankDomiciliation"] as string) || "").trim() || null,
				perceptionIban: ((formData["perceptionIban"] as string) || "").trim().replace(/ /g, "") || null,
				perceptionBic: ((formData["perceptionBic"] as string) || "").trim().replace(/ /g, "") || null,
				perceptionTitularyName: ((formData["perceptionTitularyName"] as string) || "").trim() || null,
			});

			if (!subscription.productsSubscribed) return;
			const firstPrelevementProductSubscribed = subscription.productsSubscribed.find((productSubscribed) => productSubscribed.paymentMethod === EPaymentMethod.PRELEVEMENT);
			const updatePaymentMethodResources = subscription.productsSubscribed!.map((productSubscribed) => {
				return UpdatePaymentMethodRequestResource.hydrate<UpdatePaymentMethodRequestResource>({
					bic: useSameRib
						? ((formData[`bic-${firstPrelevementProductSubscribed?.id}`] as string) || "").trim() || null
						: ((formData[`bic-${productSubscribed.id}`] as string) || "").trim() || null,
					bankDomiciliation: useSameRib
						? ((formData[`bankDomiciliation-${firstPrelevementProductSubscribed?.id}`] as string) || "").trim() || null
						: ((formData[`bankDomiciliation-${productSubscribed.id}`] as string) || "").trim() || null,
					iban: useSameRib
						? ((formData[`iban-${firstPrelevementProductSubscribed?.id}`] as string) || "").trim() || null
						: ((formData[`iban-${productSubscribed.id}`] as string) || "").trim() || null,
					organismName: ((formData[`organismName-${productSubscribed.id}`] as string) || "").trim() || null,

					titularyName: useSameRib
						? ((formData[`titularyName-${firstPrelevementProductSubscribed?.id}`] as string) || "").trim() || null
						: ((formData[`titularyName-${productSubscribed.id}`] as string) || "").trim() || null,
					paymentMethod: productSubscribed.paymentMethod,
					productSubscribedId: productSubscribed.id,
					nantieParts: FormUtils.getBooleanValue(formData, `nantieParts-${productSubscribed.id}`),
					loanAmount: parseFloat((formData[`loanAmount-${productSubscribed.id}`] as string) || "0") || null,
				});
			});

			try {
				await subscriptionResource.validateOrReject();
			} catch (error: unknown) {
				setCurrentSelectedPath("");
				console.warn(error);
				if (error instanceof Array) {
					setErrors((prev) => [...prev, ...(error as ValidationError[])]);
				}
				hasErrors = true;
			}

			for (const resource of updatePaymentMethodResources) {
				try {
					await resource.validateOrReject();
				} catch (error: unknown) {
					setCurrentSelectedPath("");
					console.warn(error);
					if (error instanceof Array) {
						const validationErrors = error.map((e) => {
							e.property = `${e.property}-${resource.productSubscribedId}`;
							return e;
						});

						setErrors((prev) => [...prev, ...validationErrors]);
					}
					hasErrors = true;
				}
			}

			if (hasErrors) return;

			await Promise.all([
				subscriptionService.put(subscriptionResource),
				updatePaymentMethodResources.forEach((resource) => subscriptionService.updatePaymentInformations(resource)),
			])
				.then(() => {
					setSubscriptionHasUnsavedChangesToFalse();
					if (currentSelectedPath !== "") return navigate(currentSelectedPath);
					goToNextStep();
				})
				.catch((error) => {
					console.warn(error);
				});
			loadSubscription();
		},
		[
			subscription,
			showCommunityInvestment,
			loadSubscription,
			useSameRib,
			setCurrentSelectedPath,
			setSubscriptionHasUnsavedChangesToFalse,
			currentSelectedPath,
			navigate,
			goToNextStep,
		],
	);

	const onFundsOriginChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = e.target;
		if (value === EFundsOrigin.other) {
			setIsOtherChecked(checked);
		}
		if (value === EFundsOrigin.savings) {
			setIsSavingChecked(checked);
		}
	}, []);

	const onPaymentFromFrenchAccountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsPaymentFromFrenchAccount(e.target.value === "true");
	}, []);

	const onPaymentByTierChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsPaymentByTier(e.target.value === "true");
	}, []);

	const onUseSameRibChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUseSameRib(e.target.checked);
	}, []);

	const onSameAsPrelevementChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setUseSamePrelevement(e.target.checked);
	}, []);

	const onCommunityInvestmentChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setCommunityInvestment(e.target.value as ECommunityInvestment);
	}, []);

	const onFundsOriginCommunityInvestmentChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
		if (!value) return;
		setFundsOriginCommunityInvestment(value.id as EFundsOriginCommunityInvestment);
	}, []);
	if (!subscriptionId) return;

	return (
		<div className={classes["root"]}>
			<Typography typo={ITypo.P_MEDIUM_BOLD} className={classes["description"]}>
				{I18n.asset.pages.subscriptions.payment_methods.description}
			</Typography>

			<Form onSubmit={onSubmit} errors={errors} onChange={setSubscriptionHasUnsavedChangesToTrue}>
				<div className={classes["form"]}>
					{subscription?.productsSubscribed!.map((productSubscribed) => (
						<span key={productSubscribed.id}>
							<PaymentMethodInputElement
								key={productSubscribed.id}
								productSubscribed={
									subscriptionWithKnowledge.productsSubscribed?.find((toSearch) => {
										return toSearch.productSheet!.name === productSubscribed.productSheet!.name && toSearch.paymentMethod === productSubscribed.paymentMethod;
									}) ?? productSubscribed
								}
								useSameRib={useSameRib}
								isFirstPrelevement={
									subscription.productsSubscribed?.find((product) => product.paymentMethod === EPaymentMethod.PRELEVEMENT)?.id === productSubscribed.id
								}
								onUseSameRibChange={onUseSameRibChange}
								onPrelevementChange={onPrelevementChange}
							/>
							<Divider style={{ border: "1px solid var(--Wild-Sand-600)", marginTop: "32px" }} />
						</span>
					))}

					<QuestionCategory title="Rib de perception des dividendes">
						{subscription.productsSubscribed!.some((productSubscribed) => productSubscribed.paymentMethod === EPaymentMethod.PRELEVEMENT) && (
							<CheckboxesInputElement
								name="sameAsPrelevement"
								options={[
									{
										label: "Utiliser le même IBAN pour le versement des dividendes",
										value: "true",
										defaultChecked: true,
									},
								]}
								onChange={onSameAsPrelevementChange}
							/>
						)}
						<InputElement
							name={`perceptionTitularyName`}
							label={I18n.asset.component.form.payment_methods.titulary_name}
							type={EInputType.TEXT}
							defaultValue={useSamePrelevement ? prelevementInputs.titularyName : ""}
						/>
						<InputElement
							name={`perceptionBankDomiciliation`}
							label={I18n.asset.component.form.payment_methods.bank_domiciliation}
							type={EInputType.TEXT}
							defaultValue={useSamePrelevement ? prelevementInputs.bankDomiciliation : ""}
						/>
						<InputElement
							name={`perceptionIban`}
							format="iban"
							label={I18n.asset.component.form.payment_methods.iban}
							type={EInputType.TEXT}
							autoComplete="on"
							defaultValue={useSamePrelevement ? prelevementInputs.iban : ""}
						/>
						<InputElement
							name={`perceptionBic`}
							label={I18n.asset.component.form.payment_methods.bic}
							type={EInputType.TEXT}
							defaultValue={useSamePrelevement ? prelevementInputs.bic : ""}
						/>
					</QuestionCategory>

					<CheckboxesInputElement
						label={I18n.asset.component.form.funds_origin}
						name="fundsOrigin"
						options={Object.keys(EFundsOrigin).map((fundOrigin) => {
							return {
								label: I18n.asset.enums.EFundsOrigin[fundOrigin as keyof typeof EFundsOrigin],
								value: fundOrigin as EFundsOrigin,
								defaultChecked: subscriptionWithKnowledge.fundsOrigin?.some((value) => value === fundOrigin),
							};
						})}
						onChange={onFundsOriginChange}
					/>
					{isOtherChecked && (
						<InputElement
							name="fundsOriginOther"
							label={I18n.asset.component.form.funds_origin_other}
							type={EInputType.TEXT}
							defaultValue={subscriptionWithKnowledge.fundsOriginOther ?? ""}
						/>
					)}
					{isSavingChecked && (
						<InputElement
							name="fundsOriginSavings"
							label={I18n.asset.component.form.funds_origin_savings}
							type={EInputType.TEXT}
							defaultValue={subscriptionWithKnowledge.fundsOriginSavings ?? ""}
							tooltipText="Par exemple : salaire/revenus professionnels, primes, cession/héritage intervenu il y a quelques années…"
						/>
					)}
					<CheckboxesInputElement
						label={I18n.asset.component.form.financial_objective}
						name="financialObjective"
						options={Object.keys(EFinancialObjective).map((key) => {
							return {
								label: I18n.asset.enums.EFinancialObjective[key as keyof typeof EFinancialObjective],
								value: EFinancialObjective[key as keyof typeof EFinancialObjective],
								defaultChecked: subscriptionWithKnowledge.financialObjective?.includes(EFinancialObjective[key as keyof typeof EFinancialObjective]),
							};
						})}
					/>

					<InputElement
						name="signaturePlace"
						type={EInputType.TEXT}
						label={I18n.asset.component.form.signature_place}
						defaultValue={subscriptionWithKnowledge.signaturePlace ?? ""}
					/>

					<RadioInputElement
						name="paymentByTier"
						label={I18n.asset.component.form.paymentByTier}
						defaultValue={subscriptionWithKnowledge.paymentByTier?.toString() ?? "false"}
						options={[
							{
								label: "Oui",
								value: "true",
							},
							{
								label: "Non",
								value: "false",
							},
						]}
						onChange={onPaymentByTierChange}
					/>

					{isPaymentByTier && (
						<InputElement
							name="paymentByTierMotive"
							type={EInputType.TEXT}
							label={I18n.asset.component.form.paymentByTierMotive}
							defaultValue={subscriptionWithKnowledge.paymentByTierMotive ?? ""}
						/>
					)}

					<RadioInputElement
						name="paymentFromFrenchAccount"
						label={I18n.asset.component.form.paymentFromFrenchAccount}
						defaultValue={subscriptionWithKnowledge.paymentFromFrenchAccount?.toString() ?? "true"}
						options={[
							{
								label: "Oui",
								value: "true",
							},
							{
								label: "Non",
								value: "false",
							},
						]}
						onChange={onPaymentFromFrenchAccountChange}
					/>

					{!isPaymentFromFrenchAccount && (
						<MuiSelectInputElement
							name="paymentFromCountry"
							label={I18n.asset.component.form.paymentFromCountry}
							defaultValue={
								subscriptionWithKnowledge.paymentFromCountry
									? {
											id: subscriptionWithKnowledge.paymentFromCountry,
											label: I18n.asset.enums.ECountry[subscriptionWithKnowledge.paymentFromCountry],
										}
									: undefined
							}
							options={Object.keys(ECountry).map((key) => {
								return {
									label: I18n.asset.enums.ECountry[key as keyof typeof ECountry],
									id: key as ECountry,
								};
							})}
						/>
					)}

					{showCommunityInvestment && (
						<>
							<RadioInputElement
								name="communityInvestment"
								label={I18n.asset.component.form.community_investment.question}
								options={[
									{
										label: I18n.asset.enums.ECommunityInvestment[ECommunityInvestment.reinvestment_of_equity],
										value: ECommunityInvestment.reinvestment_of_equity,
										tooltip: I18n.asset.enums.ECommunityInvestmentTooltip[ECommunityInvestment.reinvestment_of_equity],
									},
									{
										label:
											I18n.asset.enums.ECommunityInvestment[ECommunityInvestment.mutual_funds] +
											" " +
											I18n.asset.component.form.community_investment.co_subscription_recommendation,
										value: ECommunityInvestment.mutual_funds,
										tooltip: I18n.asset.enums.ECommunityInvestmentTooltip[ECommunityInvestment.mutual_funds],
									},
								]}
								defaultValue={subscriptionWithKnowledge.communityInvestment ?? undefined}
								onChange={onCommunityInvestmentChange}
							/>
							{communityInvestment === ECommunityInvestment.reinvestment_of_equity && (
								<MuiSelectInputElement
									name="fundsOriginCommunityInvestment"
									label={I18n.asset.component.form.community_funds_origin}
									options={Object.keys(EFundsOriginCommunityInvestment).map((fundOrigin) => ({
										id: fundOrigin as EFundsOriginCommunityInvestment,
										label: I18n.asset.enums.EFundsOriginCommunityInvestment[fundOrigin as keyof typeof EFundsOriginCommunityInvestment],
									}))}
									onChange={onFundsOriginCommunityInvestmentChange}
									defaultValue={
										fundsOriginCommunityInvestment
											? {
													id: fundsOriginCommunityInvestment,
													label: I18n.asset.enums.EFundsOriginCommunityInvestment[fundsOriginCommunityInvestment],
												}
											: null
									}
								/>
							)}

							{fundsOriginCommunityInvestment === EFundsOriginCommunityInvestment.other && communityInvestment === ECommunityInvestment.reinvestment_of_equity && (
								<InputElement
									name="fundsOriginCommunityInvestmentOther"
									label={I18n.asset.component.form.community_funds_origin_other}
									type={EInputType.TEXT}
									defaultValue={subscriptionWithKnowledge.fundsOriginCommunityInvestmentOther ?? ""}
								/>
							)}

							{subscriptionWithKnowledge.subscriptionType === ESubscriptionType.naturalPerson && (
								<>
									<InputElement
										name="communitySpouseDetails.firstName"
										type={EInputType.TEXT}
										label={I18n.asset.component.form.spouse.first_name}
										defaultValue={subscriptionWithKnowledge.communitySpouseDetails?.firstName ?? spouse?.firstName}
									/>

									<InputElement
										name="communitySpouseDetails.lastName"
										type={EInputType.TEXT}
										label={I18n.asset.component.form.spouse.last_name}
										defaultValue={subscriptionWithKnowledge.communitySpouseDetails?.lastName ?? spouse?.lastName}
									/>

									<InputElement
										name="communitySpouseDetails.email"
										type={EInputType.EMAIL}
										label={I18n.asset.component.form.spouse.email}
										defaultValue={subscriptionWithKnowledge.communitySpouseDetails?.email ?? spouse?.email}
									/>

									<InputElement
										name="communitySpouseDetails.mobilePhone"
										type={EInputType.TEXT}
										label={I18n.asset.component.form.spouse.mobile_phone}
										isNumericString={{ allowSymbols: true }}
										defaultValue={subscriptionWithKnowledge.communitySpouseDetails?.mobilePhone ?? spouse?.mobilePhone}
									/>

									<DatePickerInputElement
										name="communitySpouseDetails.birthDate"
										label={I18n.asset.component.form.spouse.birth_date}
										defaultValue={subscriptionWithKnowledge.communitySpouseDetails?.birthDate ?? spouse?.birthDate}
									/>

									<InputElement
										name="communitySpouseDetails.birthPlace"
										type={EInputType.TEXT}
										label={I18n.asset.component.form.spouse.birth_place}
										defaultValue={subscriptionWithKnowledge.communitySpouseDetails?.birthPlace ?? spouse?.birthPlace}
									/>
								</>
							)}
						</>
					)}
				</div>
				<div className={classes["footer"]}>
					<Button type="submit">
						{I18n.asset.common.next_page}
						<ArrowLongRightIcon />
					</Button>
				</div>
			</Form>
		</div>
	);
}
