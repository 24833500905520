import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { EFiscalRegime } from "common/enums/ClientSheet/EFiscalRegime";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { EMaritalStatus } from "common/enums/ClientSheet/EMaritalStatus";
import { EMatrimonialRegime } from "common/enums/ClientSheet/EMatrimonialRegime";
import { ECountry } from "common/enums/Country/ECountry";
import ClientSheetAddSpouseRequestResource from "common/resources/ClientSheet/ClientSheetAddSpouseRequestResource";
import ClientSheetResponseResource from "common/resources/ClientSheet/ClientSheetResponseResource";
import { ValidationError } from "common/resources/Resource";
import Button from "components/elements/Button";
import MessageBox from "components/elements/MessageBox";
import Typography, { ITypo } from "components/elements/Typography";
import Form from "components/materials/Form";
import CountrySelectInputElement from "components/materials/Form/CountrySelectInputElement";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement, { IMuiSelectOption } from "components/materials/Form/MuiSelectInputElement";
import NationalitySelectInputElement from "components/materials/Form/NationalitySelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import I18n from "components/materials/I18n";
import PageTemplateSubscriptions from "components/PageTemplateSubscriptions";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClientService from "services/ClientService";
import { container } from "tsyringe";

import classes from "./classes.module.scss";
import CheckboxesInputElement from "components/materials/Form/CheckboxesInputElement";
import FormUtils from "utils/FormUtils";

const defaultNationalityOption: IMuiSelectOption = {
	id: ECountry.france,
	label: I18n.asset.enums.ENationality[ECountry.france],
};

const defaultCountryOption: IMuiSelectOption = {
	id: ECountry.france,
	label: I18n.asset.enums.ECountry[ECountry.france],
};

const clientService = container.resolve(ClientService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function AddSpousToClient() {
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [civility, setCivility] = useState<ECivility>(ECivility.monsieur);
	const [nationality, setNationality] = useState<IMuiSelectOption | null>(defaultNationalityOption);
	const [spouseAge, setSpouseAge] = useState<number | null>(null);
	const [birthCountry, setBirthCountry] = useState<IMuiSelectOption | null>(defaultCountryOption);
	const [taxResidenceCountry, setTaxResidenceCountry] = useState<IMuiSelectOption | null>(defaultCountryOption);
	const [isPersonUS, setIsPersonUS] = useState<boolean>(false);
	const [isMajorUnderProtection, setIsMajorUnderProtection] = useState<boolean>(false);

	const [nonProfessionalClassificationAcknowledgment, setNonProfessionalClassificationAcknowledgment] = useState<boolean>(true);
	const { clientSheetId } = useParams();
	const [clientSheet, setClientSheet] = useState<ClientSheetResponseResource | null>(null);
	const [maritalStatus, setMaritalStatus] = useState<EMaritalStatus>(EMaritalStatus.marie);
	const [matrimonialRegime, setMatrimonialRegime] = useState<EMatrimonialRegime>(EMatrimonialRegime.communaute_reduite_aux_acquets);

	const [birthCountryHasChanged, setBirthCountryHasChanged] = useState<boolean>(false);
	const [taxResidenceCountryHasChanged, setTaxResidenceCountryHasChanged] = useState<boolean>(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!clientSheetId) return console.error("clientId is not defined");
		clientService.getClientSheetById(clientSheetId).then((clientSheet) => setClientSheet(clientSheet));
	}, [clientSheetId]);

	const onSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			e.preventDefault();
			setErrors([]);
			if (!clientSheet?.clientId) return console.error("clientId is not defined");

			ClientSheetAddSpouseRequestResource.hydrate<ClientSheetAddSpouseRequestResource>({
				id: clientSheet.id,
				spouse: {
					civility: formData["spouse.civility"] as ECivility,
					lastName: formData["spouse.lastName"] as string,
					firstName: formData["spouse.firstName"] as string,
					maidenName: ((formData["spouse.maidenName"] as string) || "").trim() || null,
					email: formData["spouse.email"] as string,
					mobilePhone: formData["spouse.mobilePhone"] as string,
					address: {
						address: clientSheet?.address?.address as string,
						zipCode: clientSheet?.address?.zipCode as string,
						city: clientSheet?.address?.country as string,
						country: clientSheet?.address?.country as ECountry,
					},
					maritalStatus: formData["spouse.maritalStatus"] as EMaritalStatus,
					matrimonialRegime: formData["spouse.matrimonialRegime"] as EMatrimonialRegime,
					nationality: formData["spouse.nationality"] as ECountry,
					birthDate: new Date(formData["spouse.birthDate"] as string),
					birthPlace: formData["spouse.birthPlace"] as string,
					birthDepartment: formData["spouse.birthDepartment"] as string,
					birthCountry: formData["spouse.birthCountry"] as ECountry,
					categorieSocialProfessionnel: formData["spouse.categorieSocialProfessionnel"] as ECategorieSocialProfessionnel,
					profession: formData["spouse.profession"] as string,
					lineOfBusiness: formData["spouse.lineOfBusiness"] as string,
					fiscalRegimes: FormUtils.getEnumValues<EFiscalRegime>(formData, "spouse.fiscalRegimes", EFiscalRegime),
					nif: formData["spouse.nif"] as string,
					taxResidenceCountry: formData["spouse.taxResidenceCountry"] as ECountry,
					legalCapacity: formData["spouse.legalCapacity"] as ELegalCapacity,
					politicallyExposed: (formData["spouse.politicallyExposed"] as string) === "true",
					personUS: (formData["spouse.personUS"] as string) === "true",
					agreementElectronicDocument: (formData["spouse.agreementElectronicDocument"] as string) === "true",
					nonProfessionalClassificationAcknowledgment: (formData["spouse.nonProfessionalClassificationAcknowledgment"] as string) === "true",
				},
			})
				.validateOrReject()
				.then((resource) => clientService.addSpouse(resource))
				.then((clientSheet) =>
					navigate(modules.pages.Subscriptions.props.pages.SelectProducts.props.path.replace(":clientSheetId", clientSheet.id).concat("?isCoSubscription=true")),
				)
				.catch((error: unknown) => {
					console.warn(error);
					if (error instanceof Array) setErrors(error);
				});
		},
		[navigate, clientSheet],
	);
	const onMaritalStatusChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.value) return;
		setMaritalStatus(e.target.value as EMaritalStatus);
	}, []);

	const onMatrimonialRegimeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.value) return;
		setMatrimonialRegime(e.target.value as EMatrimonialRegime);
	}, []);
	const onCivilityChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value as ECivility;
		setCivility(value);
	}, []);

	const handlePreviousClick = useCallback(() => navigate(-1), [navigate]);

	const onSpouseBirthDateChange = useCallback((date: Date | null) => {
		if (!date) return;
		const birthDate = new Date(date);
		const age = new Date().getFullYear() - birthDate.getFullYear();
		setSpouseAge(age);
	}, []);

	const onNationalityChange = useCallback(
		(_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
			setNationality(value);

			!birthCountryHasChanged && setBirthCountry(getCountryMuiOptionFromNationality(value));
			!taxResidenceCountryHasChanged && setTaxResidenceCountry(getCountryMuiOptionFromNationality(value));
		},
		[birthCountryHasChanged, taxResidenceCountryHasChanged],
	);

	const onBirthCountryChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
		setBirthCountry(value);
		setBirthCountryHasChanged(true);
	}, []);

	const onTaxResidenceCountryChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: IMuiSelectOption | null) => {
		setTaxResidenceCountry(value);
		setTaxResidenceCountryHasChanged(true);
	}, []);

	const onSpousePersonUSChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsPersonUS(e.target.value === "true");
	}, []);

	const onNonProfessionalClassificationAcknowledgmentChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setNonProfessionalClassificationAcknowledgment(e.target.value === "true");
	}, []);

	const onLegalCapacityChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsMajorUnderProtection(e.target.value !== ELegalCapacity.majeur_capable);
	}, []);

	const isTaxResidencyCountryNotFrance = useMemo(
		() => taxResidenceCountry?.id !== ECountry.france && taxResidenceCountry?.id !== ECountry.nouvelle_caledonie,
		[taxResidenceCountry],
	);

	return (
		<PageTemplateSubscriptions
			mainPageTitle={I18n.asset.pages.subscriptions.create_client.page_title}
			headerTitle={I18n.asset.pages.subscriptions.create_client.title}
			overridePreviousClick={{ onClick: handlePreviousClick }}>
			<div className={classes["root"]}>
				<Form onSubmit={onSubmit} errors={errors}>
					<div className={classes["form"]}>
						<Typography typo={ITypo.P_MEDIUM_BOLD}>{I18n.asset.common.souscripteur2}</Typography>

						<div className={classes["fields-container"]}>
							<RadioInputElement
								label={I18n.asset.component.form.civility}
								name="spouse.civility"
								options={[
									{
										label: I18n.asset.enums.ECivility[ECivility.monsieur],
										value: ECivility.monsieur,
									},
									{
										label: I18n.asset.enums.ECivility[ECivility.madame],
										value: ECivility.madame,
									},
								]}
								onChange={onCivilityChange}
								defaultValue={civility}
							/>
							<InputElement label={I18n.asset.component.form.last_name} name="spouse.lastName" type={EInputType.TEXT} />
							<InputElement label={I18n.asset.component.form.first_name} name="spouse.firstName" type={EInputType.TEXT} />
							{civility === ECivility.madame && <InputElement label={I18n.asset.component.form.maiden_name} name="spouse.maidenName" type={EInputType.TEXT} />}
							<InputElement label={I18n.asset.component.form.email} name="spouse.email" type={EInputType.EMAIL} />
							<InputElement
								label={I18n.asset.component.form.mobile_phone}
								name="spouse.mobilePhone"
								type={EInputType.TEXT}
								isNumericString={{ allowSymbols: true }}
							/>
							<NationalitySelectInputElement
								label={I18n.asset.component.form.nationality}
								name="spouse.nationality"
								onChange={onNationalityChange}
								defaultValue={nationality}
							/>
							<DatePickerInputElement
								label={I18n.asset.component.form.birth_date}
								name="spouse.birthDate"
								maxDate={new Date()}
								onChange={onSpouseBirthDateChange}
								isAbsoluteDate
							/>
							{spouseAge !== null && spouseAge > 80 && <MessageBox type="info" text={I18n.asset.component.form.message.warning.age_restriction_old} />}
							{spouseAge !== null && spouseAge < 18 && <MessageBox type="info" text={I18n.asset.component.form.message.warning.age_restriction_minor} />}

							<InputElement label={I18n.asset.component.form.birth_place} name="spouse.birthPlace" type={EInputType.TEXT} />
							<InputElement
								label={I18n.asset.component.form.birth_department}
								name="spouse.birthDepartment"
								type={EInputType.TEXT}
								isNumericString={{ allowSymbols: false }}
							/>
							<CountrySelectInputElement
								label={I18n.asset.component.form.birth_country}
								name="spouse.birthCountry"
								onChange={onBirthCountryChange}
								defaultValue={birthCountry}
							/>

							<MuiSelectInputElement
								label={I18n.asset.component.form.category_social_profession}
								name="spouse.categorieSocialProfessionnel"
								options={Object.values(ECategorieSocialProfessionnel).map((category) => ({
									id: category,
									label: I18n.asset.enums.ECategorieSocialProfessionnel[category],
								}))}
							/>

							<InputElement label={I18n.asset.component.form.profession} name="spouse.profession" type={EInputType.TEXT} />

							<InputElement label={I18n.asset.component.form.line_of_businness} name="spouse.lineOfBusiness" type={EInputType.TEXT} />

							<InputElement label={I18n.asset.component.form.nif} name="spouse.nif" type={EInputType.TEXT} />

							<CountrySelectInputElement
								label={I18n.asset.component.form.tax_residence_country}
								name="spouse.taxResidenceCountry"
								onChange={onTaxResidenceCountryChange}
								defaultValue={taxResidenceCountry}
							/>
							{isTaxResidencyCountryNotFrance && (
								<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.tax_residence_country_france}>
									<a href="mailto:backoffice@wenimmo.com">
										<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
										<EnvelopeIcon />
									</a>
								</MessageBox>
							)}
						</div>

						<CheckboxesInputElement
							label={I18n.asset.component.form.fiscal_regime}
							name="spouse.fiscalRegimes"
							options={Object.values(EFiscalRegime).map((fiscalRegime) => {
								return {
									label: fiscalRegime,
									value: fiscalRegime,
									defaultChecked: clientSheet?.fiscalRegimes.includes(fiscalRegime),
								};
							})}
						/>
						<RadioInputElement
							label={I18n.asset.component.form.legal_capacity}
							name="spouse.legalCapacity"
							options={[
								{
									label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_capable],
									value: ELegalCapacity.majeur_capable,
								},
								{
									label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.majeur_sous_protection],
									value: ELegalCapacity.majeur_sous_protection,
								},
								{
									label: I18n.asset.enums.ELegalCapacity[ELegalCapacity.mineur],
									value: ELegalCapacity.mineur,
								},
							]}
							defaultValue={ELegalCapacity.majeur_capable}
							onChange={onLegalCapacityChange}
						/>
						{isMajorUnderProtection && (
							<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.next_step.text}>
								<a href="mailto:backoffice@wenimmo.com">
									<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
									<EnvelopeIcon />
								</a>
							</MessageBox>
						)}

						<RadioInputElement
							label={I18n.asset.component.form.politically_exposed_person}
							name="spouse.politicallyExposed"
							options={[
								{
									label: I18n.asset.common.yes,
									value: "true",
								},
								{
									label: I18n.asset.common.no,
									value: "false",
								},
							]}
							defaultValue="false"
						/>

						<RadioInputElement
							label={I18n.asset.component.form.marital_status}
							name="spouse.maritalStatus"
							options={Object.values(EMaritalStatus).map((key) => {
								return {
									label: I18n.asset.enums.EMaritalStatus[key as EMaritalStatus],
									value: key,
								};
							})}
							onChange={onMaritalStatusChange}
							defaultValue={maritalStatus}
						/>

						{maritalStatus === EMaritalStatus.marie && (
							<>
								<RadioInputElement
									label={I18n.asset.component.form.matrimonial_regime}
									name="spouse.matrimonialRegime"
									options={Object.keys(EMatrimonialRegime)
										.sort((keyA, keyB) => {
											if (keyA === EMatrimonialRegime.communaute_meuble_et_acquets || keyB === EMatrimonialRegime.communaute_meuble_et_acquets) return 1;
											return I18n.asset.enums.EMatrimonialRegime[keyA as EMatrimonialRegime].localeCompare(
												I18n.asset.enums.EMatrimonialRegime[keyB as EMatrimonialRegime],
											);
										})
										.map((key) => {
											return {
												label: I18n.asset.enums.EMatrimonialRegime[key as EMatrimonialRegime],
												value: key,
											};
										})}
									defaultValue={EMatrimonialRegime.communaute_reduite_aux_acquets}
									onChange={onMatrimonialRegimeChange}
								/>
								{(matrimonialRegime === EMatrimonialRegime.communaute_reduite_aux_acquets ||
									matrimonialRegime === EMatrimonialRegime.communaute_universelle ||
									matrimonialRegime === EMatrimonialRegime.communaute_meuble_et_acquets) && (
									<MessageBox type="info" text={I18n.asset.component.form.message.info.recommandation_rib} />
								)}
							</>
						)}

						<RadioInputElement
							label={I18n.asset.component.form.us_person}
							name="spouse.personUS"
							options={[
								{
									label: I18n.trslt(I18n.asset.common.yes),
									value: "true",
								},
								{
									label: I18n.trslt(I18n.asset.common.no),
									value: "false",
								},
							]}
							defaultValue="false"
							onChange={onSpousePersonUSChange}
						/>
						{isPersonUS && (
							<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.next_step.text}>
								<a href="mailto:backoffice@wenimmo.com">
									<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
									<EnvelopeIcon />
								</a>
							</MessageBox>
						)}

						<RadioInputElement
							label={I18n.asset.component.form.non_professional_classification_acknowledgment}
							name="spouse.nonProfessionalClassificationAcknowledgment"
							options={[
								{
									label: I18n.asset.common.yes,
									value: "true",
								},
								{
									label: I18n.asset.common.no,
									value: "false",
								},
							]}
							defaultValue="true"
							onChange={onNonProfessionalClassificationAcknowledgmentChange}
						/>

						{!nonProfessionalClassificationAcknowledgment && (
							<MessageBox
								className={classes["message-content"]}
								type="info"
								text={I18n.asset.component.form.message.warning.non_professional_classification_acknowledgment}>
								<a href="mailto:backoffice@wenimmo.com">
									<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
									<EnvelopeIcon />
								</a>
							</MessageBox>
						)}

						<Button className={classes["button"]} type="submit" disabled={isPersonUS || isTaxResidencyCountryNotFrance || isMajorUnderProtection}>
							{I18n.asset.common.next}
						</Button>
					</div>
				</Form>
			</div>
		</PageTemplateSubscriptions>
	);
}

function getCountryMuiOptionFromNationality(nationality: IMuiSelectOption | null): IMuiSelectOption | null {
	if (!nationality) return null;
	return {
		id: nationality.id,
		label: I18n.asset.enums.ECountry[nationality.id as ECountry],
	};
}
