import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { ECategorieSocialProfessionnel } from "common/enums/ClientSheet/ECategorieSocialProfessionnel";
import { ECivility } from "common/enums/ClientSheet/ECivility";
import { ELegalCapacity } from "common/enums/ClientSheet/ELegalCapacity";
import { ECountry } from "common/enums/Country/ECountry";
import ClientSheetUpdateBeneficiaryRequestResource from "common/resources/ClientSheet/ClientSheetUpdateBeneficiaryRequestResource";
import ClientSheetUpdateLegalRepresentativeRequestResource from "common/resources/ClientSheet/ClientSheetUpdateLegalRepresentativeRequestResource";
import MessageBox from "components/elements/MessageBox";
import Typography, { ITypo } from "components/elements/Typography";
import CountrySelectInputElement from "components/materials/Form/CountrySelectInputElement";
import DatePickerInputElement from "components/materials/Form/DatePickerInputElement";
import InputElement, { EInputType } from "components/materials/Form/InputElement";
import MuiSelectInputElement from "components/materials/Form/MuiSelectInputElement";
import NationalitySelectInputElement from "components/materials/Form/NationalitySelectInputElement";
import RadioInputElement from "components/materials/Form/RadioInputElement";
import GooglePlacesAutocomplete from "components/materials/GooglePlacesAutocomplete";
import I18n from "components/materials/I18n";
import useGooglePlacesAddressComponents from "hooks/useGoolgePlaces";
import { useCallback, useMemo, useState } from "react";

import classes from "./classes.module.scss";

type IProps = {
	defaultClient?: ClientSheetUpdateBeneficiaryRequestResource | ClientSheetUpdateLegalRepresentativeRequestResource | null;
	readonly?: boolean;
	isLegalRepresentative?: boolean;
};

export default function BeneficiariesOrLegalRepresentativeInputs(props: IProps) {
	const { defaultClient: defaultValue, readonly, isLegalRepresentative } = props;
	const [civility, setCivility] = useState<ECivility>(defaultValue?.civility ?? ECivility.monsieur);
	const [age, setAge] = useState<number | null>(null);
	const [isPersonUS, setIsPersonUS] = useState<boolean>(defaultValue?.personUS ?? false);
	const [isMajorUnderProtection, setIsMajorUnderProtection] = useState<boolean>(defaultValue?.legalCapacity !== ELegalCapacity.majeur_capable);

	const isAgeAbove80 = useMemo(() => age !== null && age > 80, [age]);
	const isAgeBelow18 = useMemo(() => age !== null && age < 18, [age]);

	const { onSelectGoogleApiPrediction, googleAddressComponents } = useGooglePlacesAddressComponents();

	const onBirthDateChange = useCallback((date: Date | null) => {
		if (!date) return;
		const birthDate = new Date(date);
		const age = new Date().getFullYear() - birthDate.getFullYear();
		setAge(age);
	}, []);

	const onPersonUSChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsPersonUS(e.target.value === "true");
	}, []);

	const onLegalCapacityChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setIsMajorUnderProtection(e.target.value !== ELegalCapacity.majeur_capable);
	}, []);

	return (
		<>
			<RadioInputElement
				label={I18n.asset.component.form.civility}
				name={"civility"}
				options={[
					{
						label: I18n.asset.enums.ECivility[ECivility.monsieur],
						value: ECivility.monsieur,
					},
					{
						label: I18n.asset.enums.ECivility[ECivility.madame],
						value: ECivility.madame,
					},
				]}
				onChange={(e) => setCivility(e.target.value as ECivility)}
				defaultValue={defaultValue?.civility}
				readonly={readonly}
			/>
			<InputElement label={I18n.asset.component.form.last_name} name="lastName" type={EInputType.TEXT} defaultValue={defaultValue?.lastName} readonly={readonly} />
			{civility === ECivility.madame && (
				<InputElement label={I18n.asset.component.form.maiden_name} name="maidenName" type={EInputType.TEXT} defaultValue={defaultValue?.maidenName} readonly={readonly} />
			)}
			<InputElement label={I18n.asset.component.form.first_name} name="firstName" type={EInputType.TEXT} defaultValue={defaultValue?.firstName} readonly={readonly} />
			<InputElement
				label={I18n.asset.component.form.email + (!isLegalRepresentative ? ` (${I18n.asset.component.form.optional})` : "")}
				name="email"
				type={EInputType.EMAIL}
				defaultValue={defaultValue?.email}
				readonly={readonly}
			/>
			<InputElement
				label={I18n.asset.component.form.mobile_phone + (!isLegalRepresentative ? ` (${I18n.asset.component.form.optional})` : "")}
				name="mobilePhone"
				type={EInputType.TEXT}
				isNumericString={{ allowSymbols: true }}
				defaultValue={defaultValue?.mobilePhone}
				readonly={readonly}
			/>
			<DatePickerInputElement
				label={I18n.asset.component.form.birth_date}
				name="birthDate"
				maxDate={new Date()}
				onChange={onBirthDateChange}
				defaultValue={defaultValue?.birthDate}
				readonly={readonly}
				isAbsoluteDate
			/>
			{isAgeAbove80 && <MessageBox type="info" text={I18n.asset.component.form.message.warning.age_restriction_old} />}
			{isAgeBelow18 && <MessageBox type="info" text={I18n.asset.component.form.message.warning.age_restriction_minor} />}
			<InputElement label={I18n.asset.component.form.birth_place} name="birthPlace" type={EInputType.TEXT} defaultValue={defaultValue?.birthPlace} readonly={readonly} />
			<InputElement
				label={I18n.asset.component.form.birth_department}
				name="birthDepartment"
				type={EInputType.TEXT}
				isNumericString={{ allowSymbols: false }}
				defaultValue={defaultValue?.birthDepartment}
				readonly={readonly}
			/>
			<CountrySelectInputElement
				label={I18n.asset.component.form.birth_country}
				name="birthCountry"
				defaultValue={{ id: defaultValue?.birthCountry ?? ECountry.france, label: I18n.asset.enums.ECountry[defaultValue?.birthCountry ?? ECountry.france] }}
				readonly={readonly}
			/>
			<NationalitySelectInputElement
				label={I18n.asset.component.form.nationality}
				name="nationality"
				defaultValue={{ id: defaultValue?.nationality ?? ECountry.france, label: I18n.asset.enums.ENationality[defaultValue?.nationality ?? ECountry.france] }}
				readonly={readonly}
			/>
			{!readonly && (
				<GooglePlacesAutocomplete
					label={I18n.asset.component.form.address_automatique}
					tooltipText={I18n.asset.component.form.address_automatique_tooltip}
					onChange={onSelectGoogleApiPrediction}
				/>
			)}
			<InputElement
				label={I18n.asset.component.form.address}
				name="address.address"
				type={EInputType.TEXT}
				defaultValue={defaultValue?.address?.address ?? googleAddressComponents?.address}
				readonly={readonly}
			/>
			<InputElement
				label={I18n.asset.component.form.postal_code}
				name="address.zipCode"
				type={EInputType.TEXT}
				isNumericString={{ allowSymbols: false }}
				defaultValue={defaultValue?.address?.zipCode ?? googleAddressComponents?.zipCode}
				readonly={readonly}
			/>
			<InputElement
				label={I18n.asset.component.form.city}
				name="address.city"
				type={EInputType.TEXT}
				defaultValue={defaultValue?.address?.city ?? googleAddressComponents?.city}
				readonly={readonly}
			/>
			<CountrySelectInputElement
				label={I18n.asset.component.form.country}
				name="address.country"
				defaultValue={
					defaultValue?.taxResidenceCountry
						? { id: defaultValue?.taxResidenceCountry, label: I18n.asset.enums.ECountry[defaultValue?.taxResidenceCountry] }
						: googleAddressComponents && {
								id: defaultValue?.address?.country ?? (googleAddressComponents?.country.toLocaleLowerCase() as ECountry),
								label: I18n.asset.enums.ECountry[defaultValue?.address?.country ?? (googleAddressComponents?.country.toLocaleLowerCase() as ECountry)],
							}
				}
				readonly={readonly}
			/>
			<CountrySelectInputElement
				label={I18n.asset.component.form.tax_residence_country}
				name="taxResidenceCountry"
				defaultValue={{
					id: defaultValue?.taxResidenceCountry ?? ECountry.france,
					label: I18n.asset.enums.ECountry[defaultValue?.taxResidenceCountry ?? ECountry.france],
				}}
				readonly={readonly}
			/>

			<MuiSelectInputElement
				label={I18n.asset.component.form.category_social_profession + (!isLegalRepresentative ? ` (${I18n.asset.component.form.optional})` : "")}
				name="categorieSocialProfessionnel"
				options={Object.values(ECategorieSocialProfessionnel).map((category) => ({
					id: category,
					label: I18n.asset.enums.ECategorieSocialProfessionnel[category],
				}))}
				defaultValue={
					defaultValue?.categorieSocialProfessionnel && {
						id: defaultValue?.categorieSocialProfessionnel,
						label: I18n.asset.enums.ECategorieSocialProfessionnel[defaultValue?.categorieSocialProfessionnel],
					}
				}
				readonly={readonly}
			/>

			<InputElement
				label={I18n.asset.component.form.profession + (!isLegalRepresentative ? ` (${I18n.asset.component.form.optional})` : "")}
				name="profession"
				type={EInputType.TEXT}
				defaultValue={defaultValue?.profession}
				readonly={readonly}
			/>

			<InputElement
				label={I18n.asset.component.form.line_of_businness + (!isLegalRepresentative ? ` (${I18n.asset.component.form.optional})` : "")}
				name="lineOfBusiness"
				type={EInputType.TEXT}
				defaultValue={defaultValue?.lineOfBusiness}
				readonly={readonly}
			/>

			<InputElement label={I18n.asset.component.form.nif} name="nif" type={EInputType.TEXT} defaultValue={defaultValue?.nif} readonly={readonly} />

			<RadioInputElement
				label={I18n.asset.component.form.legal_capacity}
				name="legalCapacity"
				options={Object.values(ELegalCapacity).map((legalCapacity) => ({
					value: legalCapacity,
					label: I18n.asset.enums.ELegalCapacity[legalCapacity],
				}))}
				onChange={onLegalCapacityChange}
				defaultValue={defaultValue?.legalCapacity ?? ELegalCapacity.majeur_capable}
				readonly={readonly}
			/>
			{isMajorUnderProtection && (
				<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.next_step.text}>
					<a href="mailto:backoffice@wenimmo.com">
						<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
						<EnvelopeIcon />
					</a>
				</MessageBox>
			)}

			<RadioInputElement
				label={I18n.asset.component.form.us_person}
				name="personUS"
				options={[
					{
						label: I18n.trslt(I18n.asset.common.yes),
						value: "true",
					},
					{
						label: I18n.trslt(I18n.asset.common.no),
						value: "false",
					},
				]}
				defaultValue={defaultValue?.personUS.toString() ?? "false"}
				onChange={onPersonUSChange}
				readonly={readonly}
			/>
			{isPersonUS && (
				<MessageBox className={classes["message-content"]} type="warning" text={I18n.asset.component.form.message.warning.next_step.text}>
					<a href="mailto:backoffice@wenimmo.com">
						<Typography typo={ITypo.P_SMALL}> {I18n.asset.component.form.message.warning.next_step.mailTo}</Typography>
						<EnvelopeIcon />
					</a>
				</MessageBox>
			)}
			<RadioInputElement
				label={I18n.asset.component.form.politically_exposed_person}
				name="politicallyExposed"
				options={[
					{
						label: I18n.asset.common.yes,
						value: "true",
					},
					{
						label: I18n.asset.common.no,
						value: "false",
					},
				]}
				defaultValue={defaultValue?.politicallyExposed.toString() ?? "false"}
				readonly={readonly}
			/>

			<RadioInputElement
				label={I18n.asset.component.form.agreement_electronic_document}
				name="agreementElectronicDocument"
				options={[
					{
						label: I18n.asset.common.yes,
						value: "true",
					},
					{
						label: I18n.asset.common.no,
						value: "false",
					},
				]}
				defaultValue={defaultValue?.agreementElectronicDocument ? defaultValue?.agreementElectronicDocument.toString() : "true"}
				readonly={readonly}
			/>
		</>
	);
}
